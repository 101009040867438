<template>
  <div>
    <div class="p-4 mt-4">
      <h3>📫 Historial de notificaciones</h3>
      <vs-divider></vs-divider>
    </div>
    <div class="vx-col w-full lg:w-12/12 mb-base">
      <vx-card>
        <vx-timeline v-if="ultimasNotificaciones.length > 0" :data="ultimasNotificaciones" />
        <div v-if="ultimasNotificaciones.length == 0" class="p-4 mt-4 text-center">
          <h3>Por el Momento no tiene notifiaciones</h3>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import VxTimeline from "@/components/timeline/VxTimeline";
import gql from "graphql-tag";
export default {
  data() {
    return {
      getUser: []
    };
  },
  apollo: {
    getUser: {
      query: gql`
        query($id: ID!) {
          getUser(id: $id) {
            _id
            notifications {
              name
              description
              icon
              date_created
              type
            }
          }
        }
      `,
      variables() {
        return {
          id: localStorage.user_id
        };
      },
      pollInterval: 700
    }
  },
  computed: {
    ultimasNotificaciones() {
      const notificaciones = this.getUser.notifications;
      let ultimosRegistros = notificaciones;
      ultimosRegistros.sort(function(a, b) {
        return new Date(b.date_created) - new Date(a.date_created);
      });
      return ultimosRegistros;
    }
  },
  components: {
    VxTimeline
  },
  mounted(){
            this.$store.dispatch("autoLogin");
        }
};
</script>

<style lang="scss" scoped>
</style>