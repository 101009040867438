<!-- =========================================================================================
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <ul class="vx-timeline">
      <li v-for="item in data" :key="item.date_created">
          <div class="timeline-icon" :class="`bg-${item.type}`">
              <feather-icon :icon="item.icon" svgClasses="text-white stroke-current w-5 h-5" />
          </div>
          <div class="timeline-info">
              <p class="font-semibold">{{ item.name }}</p>
              <span class="activity-desc">{{ item.description }}</span>
          </div>
          <small class="text-grey activity-e-time">{{ fechaFormateada(item.date_created) }}</small>
      </li>
  </ul>
</template>

<script>
export default {
  name: "vx-timeline",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    fechaFormateada(fecha) {
      let nuevaFecha = new Date(fecha);
      let dd = nuevaFecha.getDate();
      let mm = nuevaFecha.getMonth() + 1;
      let yyy = nuevaFecha.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      let datefull = dd + "/" + mm + "/" + yyy;

      return datefull;
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
</style>
